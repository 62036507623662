$(document).ready(function () {

    jQuery.validator.addMethod("moduleTitle", function (value, element) {
            var moduleTitle = $("input[name='module_title[]']")
                .map(function () {
                    return $(this).val();
                }).get();
            var moduleCode = $("input[name='module_code[]']")
                .map(function () {
                    return $(this).val();
                }).get();
            if ($(element)[0].id === 'module_title_1') {
                if (moduleTitle[0] === "") {
                    return false;
                }
                return true
            }

            // for ($i = 0; $i <= 5 - 1; $i++) {
            //     if (moduleTitle[$i] !== "" && moduleCode[$i] === "") {
            //         return false;
            //     }
            // }
            return true;

        }, "Module title 1 required."
    );
    jQuery.validator.addMethod("moduleCode", function (value, element) {
            var moduleTitle = $("input[name='module_title[]']")
                .map(function () {
                    return $(this).val();
                }).get();
            var moduleCode = $("input[name='module_code[]']")
                .map(function () {
                    return $(this).val();
                }).get();
            if ($(element)[0].id === 'module_code_1') {
                if (moduleCode[0] === "") {
                    return false;
                }
                return true
            }
            // for ($i = 0; $i <= 5 - 1; $i++) {
            //     if (moduleCode[$i] !== "" && moduleTitle[$i] === "") {
            //         return false;
            //     }
            // }
            return true;

        }, "Module code 1 required."
    );

    jQuery.validator.addMethod("examSession", function (value, element) {
            var examSession = $("select[name='exam_session[]']")
                .map(function () {
                    return $(this).val();
                }).get();
            if ($(element)[0].id === 'exam_session_1') {
                if (examSession[0] === "") {
                    return false;
                }
                return true
            }
            return true;

        }, "Exam Session 1 required."
    );
    $.validator.addMethod("wordCount", function (value, element, wordCount) {

        return value.split(' ').length <= wordCount;

    }, 'Exceeded word count, Allowed word count is ' + 300 + ' or less');

    $.validator.addMethod(
        "email_check",
        function(value, element, regexp) {
            var re = new RegExp("^[a-zA-Z0-9_\\.%\\+\\-]+@[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,}$");
            return this.optional(element) || re.test(value);
        },
        "Please enter a valid email address."
    );
    $("#appeal").validate({
        rules: {
            first_name: {
                required: true
            },
            last_name: {
                required: true
            },
            student_id_number: {
                required: true
            },
            student_email: {
                required: true,
                // email: true,
                email_check: true
            }, student_phone: {
                required: true
            }, college: {
                required: true
            }, program: {
                required: true
            }, student_category: {
                required: true
            }, exam_session: {
                required: true
            }, "grounds_appeal[]": {
                required: true
            }, statement: {
                required: true,
                wordCount: 300
            }, file: {
                required: true,
                extension: "pdf|doc|docx"
            }, billing_email: {
                required: true,
                // email: true,
                email_check: true
            }, billing_phone: {
                required: true,
                minlength: 9,
                maxlength: 19
            }, billing_intl: {
                required: true,
                minlength: 1,
                maxlength: 3
            }, billing_address1: {
                required: true
            }, billing_city: {
                required: true
            }, billing_postcode: {
                required: true
            }, billing_country: {
                required: true
            }, declaration: {
                required: true
            }, 'module_title[]': {
                moduleTitle: true
            }, 'module_code[]': {
                moduleCode: true
            }, section1: {
                required: true
            }, section2: {
                required: true
            }, "section3[]": {
                required: true
            }
        },
        messages: {
            file: {
                extension: "Please upload only PDF files."
            },
            // section1: {
            //     required: "Please select an option",
            // },
            // section2: {
            //     required: "Please select an option",
            // },
            // "section3[]": {
            //     required: "Please tick 1 or more matching options",
            // }
        },
        submitHandler: function (form) {
            var formData = new FormData($('#appeal')[0]);
            $.ajax({
                url: $('#appeal').prop('action'),
                method: "post",
                processData: false,
                contentType: false,
                data: formData,
                success: function (data) {
                    setHPPFields(data)
                },
                error: function (e) {
                    //error
                }
            });
        }
    });

    $("#recheck").validate({
        rules: {
            first_name: {
                required: true
            },
            last_name: {
                required: true
            },
            student_id_number: {
                required: true
            },
            student_email: {
                required: true,
                // email: true,
                email_check: true
            }, student_phone: {
                required: true
            }, program: {
                required: true
            }, file: {
                required: true,
                extension: "pdf|doc|docx"
            }, billing_email: {
                required: true,
                // email: true,
                email_check: true
            }, billing_phone: {
                required: true,
                minlength: 9,
                maxlength: 19
            }, billing_intl: {
                required: true,
                minlength: 1,
                maxlength: 3
            }, billing_address1: {
                required: true
            }, billing_city: {
                required: true
            }, billing_postcode: {
                required: true
            }, billing_country: {
                required: true
            }, declaration: {
                required: true
            }, 'module_title[]': {
                moduleTitle: true
            }, 'module_code[]': {
                moduleCode: true
            }, 'exam_session[]': {
                examSession: true
            }
        },
        messages: {
            file: {
                extension: "Please upload only PDF files."
            },
        },
        submitHandler: function (form) {
            var formData = new FormData($('#recheck')[0]);
            $.ajax({
                url: $('#recheck').prop('action'),
                method: "post",
                processData: false,
                contentType: false,
                data: formData,
                success: function (data) {
                     setHPPFields(data)
                },
                error: function (e) {
                    //error
                }
            });
        }
    });

    $("#repay-from").validate({
        rules: {
            billing_email: {
                required: true,
                // email: true,
                email_check: true
            }, billing_phone: {
                required: true,
                minlength: 9,
                maxlength: 19
            }, billing_intl: {
                required: true,
                minlength: 1,
                maxlength: 3
            }, billing_address1: {
                required: true
            }, billing_city: {
                required: true
            }, billing_postcode: {
                required: true
            }, billing_country: {
                required: true
            }
        }, submitHandler: function (form) {
            var formData = new FormData($('#repay-from')[0]);
            let orderId = $(location).attr('href').split("/").splice(0, 5)[4]
            if (typeof orderId !== 'undefined') {
                $.ajax({
                    url: $('#repay-from').prop('action'),
                    method: "post",
                    processData: false,
                    contentType: false,
                    data: formData,
                    success: function (data) {
                        setHPPFields(data)
                    },
                    error: function (e) {
                        //error
                    }
                });
            }
        }
    });
    // $("#submit").click(function (e) {
    //     e.preventDefault();
    //     var formData = new FormData($('#appeal')[0]);
    //     $.ajax({
    //         url: "/",
    //         method: "post",
    //         processData: false,
    //         contentType: false,
    //         data: formData,
    //         success: function (data) {
    //             setHPPFields(data)
    //         },
    //         error: function (e) {
    //             //error
    //         }
    //     });
    // });
    //
    // $('#repay-btn').click(function (e) {
    //     e.preventDefault();
    //     var formData = new FormData($('#repay-from')[0]);
    //     let orderId = $(location).attr('href').split("/").splice(0, 5)[4]
    //     console.log(orderId)
    //     if (typeof orderId !== 'undefined') {
    //         $.ajax({
    //             url: "/order/" + orderId,
    //             method: "post",
    //             processData: false,
    //             contentType: false,
    //             data: formData,
    //             success: function (data) {
    //                 setHPPFields(data)
    //             },
    //             error: function (e) {
    //                 //error
    //             }
    //         });
    //     }
    //
    // });

    function setHPPFields(data) {
        $('#payment').attr('action', data.ACTION);
        $("[name='ACTION']").val(data.ACTION);
        $("[name='MERCHANT_ID']").val(data.MERCHANT_ID);
        $("[name='ACCOUNT']").val(data.ACCOUNT);
        $("[name='CURRENCY']").val(data.CURRENCY);
        $("[name='MERCHANT_RESPONSE_URL']").val(data.MERCHANT_RESPONSE_URL);
        $("[name='TIMESTAMP']").val(data.TIMESTAMP);
        $("[name='ORDER_ID']").val(data.ORDER_ID);
        $("[name='AMOUNT']").val(data.AMOUNT);
        $("[name='SHA1HASH']").val(data.SHA1HASH);
        $("[name='HPP_CUSTOMER_EMAIL']").val(data.PAY_EMAIL);
        $("[name='HPP_CUSTOMER_PHONENUMBER_MOBILE']").val(data.PAY_PHONE);
        $("[name='HPP_BILLING_STREET1']").val(data.PAY_BILLING_ADDRESS_1);
        $("[name='HPP_BILLING_STREET2']").val(data.PAY_BILLING_ADDRESS_2);
        $("[name='HPP_BILLING_STREET3']").val(data.PAY_BILLING_ADDRESS_3);
        $("[name='HPP_BILLING_CITY']").val(data.PAY_CITY);
        $("[name='HPP_BILLING_POSTALCODE']").val(data.PAY_POSTALCODE);
        $("[name='HPP_BILLING_COUNTRY']").val(data.PAY_COUNTRY);
        $('#payment').submit()
    }

    let file_input = $('input[type="file"]');
    let file_input_label = file_input.prev().html()
    file_input.change(function () {
        if ($(this).val() === "") {
            file_input.prev().html(file_input_label)
            return
        }
        $(this).prev().html($(this).val().split('\\').pop())
    });
});
