$(document).ready(function () {

    if(getUrlVars()['lang'] ==="ie"){
        jQuery.extend(jQuery.validator.messages, {
            required: "Tá an réimse seo éigeantach",
            remote: "Ceartaigh an réimse seo.",
            email: "Cuir isteach seoladh ríomhphoist bailí.",
            url: "Cuir isteach URL bailí.",
            date: "Cuir isteach dáta bailí.",
            dateISO: "Cuir isteach dáta bailí (ISO).",
            number: "Cuir isteach uimhir bhailí.",
            digits: "Ná cuir isteach ach digití.",
            creditcard: "Cuir isteach uimhir bhailí cárta creidmheasa.",
            equalTo: "Cuir isteach an luach céanna arís.",
            accept: "Cuir isteach luach a bhfuil iarmhír bhailí aige.",
            maxlength: jQuery.validator.format("Uaslíon na gcarachtar: {0}."),
            minlength: jQuery.validator.format("Íoslíon na gcarachtar: {0}."),
            rangelength: jQuery.validator.format("Bíodh luach na gcarachtar idir {0} agus {1} ar fad."),
            range: jQuery.validator.format("Cuir isteach luach idir {0} agus {1}."),
            max: jQuery.validator.format("Cuir isteach luach níos lú ná nó cothrom le {0}."),
            min: jQuery.validator.format("Cuir isteach luach níos mó ná nó cothrom le {0}."),
            moduleTitle: "Teideal Mhodúl 1 ag teastáil.",
            moduleCode: "Cód Mhodúl 1 ag teastáil.",
            wordCount: "Sáraíodh an líon focal, is é 300 nó níos lú an líon focal ceadaithe",
            extension: "Ná huaslódáil ach comhaid PDF, DOC nó DOCX.\n"
        });
    }


    function getUrlVars()
    {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }
});

